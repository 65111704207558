import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useLocalStorage } from 'react-use';
import { Controller, useForm } from 'react-hook-form';
import { TextInput, Button, Alert } from '@hagerty/react-components';
import { useTranslation } from 'react-i18next';

import { Translate } from 'src/components/Translate';
import { useByPartnerCustomerIdMembership } from 'src/api/useByPartnerCustomerIdMembership';
import { useAllowMarketingToMember, AllowMarketingToMemberInput } from 'src/api/useAllowMarketingToMember';
import { Checkbox } from 'src/components/designSystems';
import { AllowMarketingToMemberStatus } from 'src/constants/AllowMarketingToMemberStatus';
import { useAuthContext } from 'src/components/AuthProvider';
import LoadingContainer from 'src/components/LoadingContainer';
import scrollToTop from 'src/helpers/scrollToTop';
import { getUserFromLocalStorage } from 'src/helpers/setHeaders';
import { validateEmail } from 'src/helpers/validate-email';
import { EMAIL_PATTERN, EMAIL_INVALID_RESOURCE, EMAIL_REQUIRE_CLIENT_RESOURCE } from 'src/constants/email';
import { useTrackingContext } from 'src/components/TrackingContext';
import { trackEvent } from 'src/services/tracker';

type ContractFormData = {
  email: string;
  consentTerms?: false;
  submit: string;
};

const ConsentForm: React.FC = () => {
  const agent = getUserFromLocalStorage();
  const { data: membership } = useByPartnerCustomerIdMembership();
  const { userId } = useAuthContext();
  const { trackInteraction } = useTrackingContext();
  const allowedToSell = membership?.associatedAgent?.allowedToSell;
  const defaultValues = { email: membership?.email || '', consentTerms: false, submit: '' };
  const {
    register,
    errors,
    control,
    formState: { isSubmitting },
    watch,
    setValue,
    handleSubmit,
  } = useForm({ mode: 'onChange', defaultValues });
  const { t } = useTranslation();
  const { brokers } = useAuthContext();
  const [displayAlert, setDisplayAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>(t('generic.error.message'));
  const [values, setValues] = useState<AllowMarketingToMemberInput>();

  const allowMarketingToMember = useAllowMarketingToMember(values);

  const [flags] = useLocalStorage('@FLAGS', {});
  if (flags['DEBUG']) {
    console.log(defaultValues, watch());
  }

  useEffect(() => {
    if (membership?.email) setValue('email', membership?.email);
  }, [membership?.email]);

  const title = allowedToSell
    ? 'state-farm.consent-form.licensed-state.title'
    : 'state-farm.consent-form.non-licensed-state.title';
  const description = allowedToSell
    ? 'state-farm.consent-form.licensed-state.description'
    : 'state-farm.consent-form.non-licensed-state.description';
  const submitText = allowedToSell ? 'state-farm.consent.send' : 'state-farm.consent.save';

  const checkboxClick = () => {
    trackInteraction(
      'checkbox',
      'By checking this box, I agree that I have read the Hagerty Drivers Club Consent to Market to the customer.*'
    );
  };

  const handleValidateEmail = (value: string) =>
    validateEmail({
      email: value,
      agentEmail: brokers?.agentEmail,
      debug: flags['DEBUG'],
      message: t(EMAIL_REQUIRE_CLIENT_RESOURCE),
    });

  const onSubmit = async (data: ContractFormData) => {
    const shouldContinueToPurchase = data.submit === 'consent and purchase';
    if (shouldContinueToPurchase) {
      trackInteraction('button', 'Continue to purchase');
    } else {
      trackInteraction('button', allowedToSell ? 'Consent to Marketing' : 'Save and continue');
    }

    setValues({
      partnerCustomerId: membership.partnerCustomer.id,
      email: data.email,
      agencyId: membership.associatedAgent.agencyId,
      agentId: agent.profile.AgentID,
      bridgeAgentId: agent.profile.BridgeAgentID,
      alternateAgencyId: agent.profile.AlternateAgencyID,
      bridgeAgentAlias: membership.lastBridgingBroker.bridgeAgentAlias,
      workflowId: membership.lastBridgingBroker.workflow.externalWorkflowID,
      workflowType: membership.lastBridgingBroker.workflow.externalWorkflowType,
    });

    await allowMarketingToMember()
      .then((response) => {
        if (response.data?.member) {
          const allowMarketingErrors = response.data.member.allowMarketingToMember.errors;
          const isConsentAlreadyGiven =
            allowMarketingErrors &&
            allowMarketingErrors[0].__typename === AllowMarketingToMemberStatus.EMAIL_CONSENT_ALREADY_GIVEN;

          if (allowMarketingErrors) {
            trackEvent({ alert_details: response.data.member.allowMarketingToMember.errors[0].message });
            setAlertMessage(response.data.member.allowMarketingToMember.errors[0].message);
            setDisplayAlert(true);
            scrollToTop();

            if (isConsentAlreadyGiven) {
              if (shouldContinueToPurchase) {
                navigate(`/checkout/information?userId=${userId}&consent-skipped=1`);
              } else {
                navigate('/state-farm/consent/captured', { state: { consentEmail: data.email } });
              }
            }
          } else if (shouldContinueToPurchase) {
            navigate(`/checkout/information?userId=${userId}&consent-skipped=1`);
          } else {
            navigate('/state-farm/consent/confirmed', { state: { consentEmail: data.email } });
          }
        } else return Promise.reject(response);
      })
      .catch((e) => {
        trackEvent({ alert_details: e?.errors?.message || alertMessage });
        if (e?.errors?.message) setAlertMessage(e?.errors?.message);
        setDisplayAlert(true);
        scrollToTop();
      });
  };

  return (
    <form className="consent-form state-farm-container" onSubmit={handleSubmit(onSubmit)}>
      {displayAlert && <Alert alertType="danger" messageText={alertMessage} />}
      <Translate as="h2" resourceKey={title} useHtml />
      <Translate as="p" className="text-lead_2" resourceKey={description} />
      <div className="col xs-size_12-of-12 lg-size_7-of-12">
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: {
              value: EMAIL_PATTERN,
              message: t(EMAIL_INVALID_RESOURCE),
            },
            validate: handleValidateEmail,
          }}
          render={({ value, onChange }) => (
            <TextInput
              inputLabel={t('state-farm.consent-form.email.label')}
              tooltipText={t('state-farm.consent.email.tip')}
              hasFormElementWrapper
              name="email"
              value={value}
              onChange={onChange}
              status={errors['email'] ? 'Error' : 'Default'}
              hintText={
                errors['email']?.type === 'required' ? t('form.validation.requiredField') : errors['email']?.message
              }
            />
          )}
        />
      </div>
      <div className="col xs-size_12-of-12 lg-size_10-of-12">
        <div className="checkbox__container">
          <Checkbox
            name="consentTerms"
            reference={register({ required: true })}
            error={errors['consentTerms']}
            onClick={checkboxClick}
          >
            <Translate as="p" className="text_4" resourceKey={'state-farm.consent-form.checkbox.label'} />
          </Checkbox>
        </div>
      </div>
      <input type="hidden" name="submit" ref={register()} />
      <div className="inset-m" />
      <Translate as="h5" resourceKey={'state-farm.consent-form.disclaimer.title'} />
      <div className="inset-s" />
      <Translate as="p" className="text_4" resourceKey={'state-farm.consent-form.disclaimer.description'} useHtml />
      <div className="inset-m" />
      {isSubmitting ? (
        <LoadingContainer />
      ) : (
        <div className="button-group">
          {!allowedToSell && (
            <Button
              buttonType={'Outline'}
              children={t('state-farm.consent.skip')}
              buttonSize={'Medium'}
              onClick={(e) => {
                e.preventDefault();
                trackInteraction('button', 'Skip consent');
                navigate('/state-farm/consent/skip');
              }}
            />
          )}
          <Button
            type="submit"
            buttonType={allowedToSell ? 'Outline' : 'Primary'}
            children={t(submitText)}
            buttonSize={'Medium'}
          />
          {allowedToSell && (
            <Button
              children={t('state-farm.consent.continue')}
              buttonSize={'Medium'}
              onClick={() => {
                setValue('submit', 'consent and purchase');
              }}
            />
          )}
        </div>
      )}
    </form>
  );
};

export { ConsentForm };
