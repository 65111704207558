import { useMutation } from '@apollo/client';

import { AllowMarketingToMember } from './types';
import { stfrm } from 'src/constants/agent';
import AllowMarketingToMemberMutation from './graphql/brokers/mutations/AllowMarketingToMemberMutation.graphql';

export type AllowMarketingToMemberInput = {
  partnerCustomerId?: string;
  email?: string;
  agencyId?: string;
  agentId?: string;
  bridgeAgentId?: string;
  alternateAgencyId?: string;
  bridgeAgentAlias?: string;
  workflowId?: string;
  workflowType?: string;
};

const useAllowMarketingToMember = (data?: AllowMarketingToMemberInput) => {
  const [allowMarketingToMember] = useMutation<AllowMarketingToMember>(AllowMarketingToMemberMutation, {
    variables: {
      partnerCustomerId: data?.partnerCustomerId,
      affiliation: stfrm,
      email: data?.email,
      agent: {
        agencyId: data?.agencyId,
        agentId: data?.agentId,
        bridgeAgentId: data?.bridgeAgentId,
        alternateAgencyId: data?.alternateAgencyId,
        bridgeAgentAlias: data?.bridgeAgentAlias,
        workflowId: data?.workflowId,
        workflowType: data?.workflowType,
        eligibleForFee: true,
      },
      canSendMarketingEmail: true,
    },
  });
  return allowMarketingToMember;
};

export { useAllowMarketingToMember };
