import React, { useEffect, useState } from 'react';
import { Launch16 } from '@hagerty/icons';
import { Helmet } from 'react-helmet';

import { HelpBox } from 'src/components/molecule';
import { Translate } from 'src/components/Translate';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

import { ConsentForm } from './_consent-form';
import { DownloadLink } from './_download-link';
import './consent.scss';
import { useByPartnerCustomerIdMembership } from 'src/api/useByPartnerCustomerIdMembership';

const metadata = {
  title: 'Marketing Consent | HDC for State Farm',
};

const Consent = () => {
  const [callOnce, setCallOnce] = useState(false);
  const { trackView, trackInteraction } = useTrackingContext();
  const { data, loading } = useByPartnerCustomerIdMembership();

  useTracking('sf_consent_view', null, true);

  const websiteClick = () => {
    trackInteraction('link', 'Visit website');
  };

  useEffect(() => {
    if (loading || callOnce || !trackView) return;

    trackView('sf_consent_view', null, {
      virtual_page_name: data?.associatedAgent?.allowedToSell ? 'consent-can-purchase' : 'consent-cannot-purchase',
    });

    setCallOnce(true);
  }, [loading, trackView]);

  return (
    <>
      <Helmet title={metadata.title} />
      <section className="information">
        <div className="sidebar-layout">
          <ConsentForm />
          <div className="help-box-sidebar" data-cy="consent-help-box">
            <HelpBox
              header={'state-farm.consent-form.help-box.title'}
              content={
                <div>
                  <div className="inset-xs" />
                  <DownloadLink />
                  <a
                    href={process.env.HDC_HAGERTY_DRIVERS_CLUB_ROOT_URL}
                    target="__blank"
                    rel="noopener noreferrer"
                    data-cy="website-link"
                    onClick={websiteClick}
                  >
                    <Translate resourceKey={'state-farm.consent-form.help-box.website'} />
                    <Launch16 className={'icon'} />
                  </a>
                </div>
              }
              icon={'magazine'}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Consent;
