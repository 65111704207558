import React from 'react';
import { withPrefix } from 'gatsby';
import { DownloadApplication16 } from '@hagerty/icons';
import { Translate } from 'src/components/Translate';
import getPdfVersion from 'src/helpers/getPdfVersion';
import { useTrackingContext } from 'src/components/TrackingContext';

const DownloadLink: React.FC = () => {
  const pdfVersion = getPdfVersion();
  const { trackInteraction } = useTrackingContext();

  const downloadClick = () => {
    trackInteraction('link', 'Download PDF');
  };

  return (
    <a
      href={withPrefix(`/terms/agent-download/HDC-Benefits-One-Pager-${pdfVersion}.pdf`)}
      aria-label="Download"
      target="__blank"
      rel="noopener noreferrer"
      data-cy="download-benefits-pdf"
      onClick={downloadClick}
    >
      <Translate resourceKey="state-farm.consent-form.help-box.download" />
      <DownloadApplication16 className={'icon'} />
    </a>
  );
};

export { DownloadLink };
